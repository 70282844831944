import axios from 'axios';

export const baseurl = axios.create({
    baseURL: 'http://silicaapi.condominio.co.mz/',
    // baseURL: 'http://127.0.0.1:8000/',
});

const apirequest = axios.create({
    baseURL: 'http://silicaapi.condominio.co.mz/',
    // baseURL: 'http://127.0.0.1:8000/'
});

baseurl.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (token != null) {
        config.headers.Authorization = `Token ${JSON.parse(token)}`;
    }
    return config;
});


baseurl.interceptors.response.use(function (response) {
    return response
},)

export default apirequest;